import { Injectable } from '@angular/core';

import * as  ExternalDeviceService   from '../generated/ExternalDevice/services/service';
import * as  ExternalLocation  from '../generated/ExternalLocation/services/service';
import * as  ExternalDeviceType  from '../generated/ExternalDeviceType/services/service';
import {ApiConfiguration} from "../generated/ExternalDevice/api-configuration";

import {HttpClient, HttpHandler, HttpRequest} from "@angular/common/http";
import {Base64} from "js-base64";
import {sha256} from "js-sha256";
import {ExternalLocationsRequest} from "../generated/ExternalLocation";
import {DeviceCreateApi} from "../generated/ExternalDevice/models/device-create-api";
import {skipAuthContext} from "../../../interceptors/auth.interceptor";

const prepareHttp = (auth: string, handler: HttpHandler) => {
  return  new Proxy(
      new HttpClient(handler),
      {
        get: (target, prop, receiver) => {
          if (prop in target) {
            if (typeof target[prop] === 'function' ) {
              if ( prop === 'request')
                return function() {
                  if (arguments?.[0]?.headers && auth) {
                    arguments[0].headers = arguments?.[0]?.headers?.append('Authorization','Basic ' + auth);
                  }
                  arguments?.[0]?.context?.set(skipAuthContext, true);
                  // @ts-expect-error
                  return target[prop].apply(target,  arguments );
                }
              else
                return target[prop].bind(target);
            } else {
              return target[prop];
            }
          } else {
            throw new Error('HttpNotStart');
          }
        }
      }
    )
}

@Injectable()
export class ExternalApiService {
  constructor(
    private config: ApiConfiguration,
    private handler: HttpHandler
  ) {}

  public rootUrl = 'http://work:9007';
  private authString = '';

  public setAuth(login: string, password: string) {
    this.authString = Base64.encode(login + ':' + sha256(password) );
  }

  createDevice$(body: Array<DeviceCreateApi>, partnerId: number) {
    const http = prepareHttp( this.authString, this.handler );
    const service = new ExternalDeviceService.Service(this.config, http);
    service.rootUrl = this.rootUrl;

    return service.postPartnerPartneridApi20Device({
      partnerId,
      body
    })

  }

  queryLocations$( body: ExternalLocationsRequest, partnerId: number ) {

    const http = prepareHttp( this.authString, this.handler );
    const service = new ExternalLocation.Service(this.config, http);
    service.rootUrl = this.rootUrl;

    return service.postPartnerPartneridApi20LocationQuery({
      partnerId,
      body
    })

  }


  queryDeviceType$( type: string, partnerId: number ) {

    const http = prepareHttp( this.authString, this.handler );
    const service = new ExternalDeviceType.Service(this.config, http);
    service.rootUrl = this.rootUrl;

    return service.postPartnerPartneridApi20Devicetype({
      partnerId
    })

  }

}
