
import {Component} from "@angular/core";

import {CategoryApiService} from "../../../../core/service/api/category-api/category-api.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'sku-add-popup',
  templateUrl: "./sku-add-popup.component.html",
  styleUrls: ['./sku-add-popup.component.scss' ],
  providers: [
    CategoryApiService
  ]
})
 export class SkuAddPopupComponent {

  public model: any = {};

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  saveGood() {
    if (this.model.name && this.model.sku) {
      this.activeModal.close(this.model);
    }
  }
}
