import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import { catchError, concatAll, map, switchMap } from 'rxjs/operators';
import { concat, Observable, of, Subscriber, throwError } from 'rxjs';
import {
  CategoryApi,
  CategoryApiService as CategoryApiServiceGen, RawCategoryTree,
} from '../generated/Category';
import {ApiRequestHandlerClass} from "../../../class/api-request-handler.class";
import {LocalStorageService} from "../../localStorage/local-storage.service";
import {ParamsHandler} from "../../../class/ParamsHandler/Params.handler";
import {ValidateFieldClass} from "../../../class/validate-field.class";
import {SearchTreeApiRequest, TreeApiRequest} from "./category-api.values";

@Injectable({
  providedIn: 'root',
})
export class CategoryApiService extends ApiRequestHandlerClass {
  constructor(
    private partnerApiService: PartnerApiService,
    private categoryApiService: CategoryApiServiceGen,
    localStorageService: LocalStorageService
  ) {
    super();

    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );

    this.queryPlayersParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;

  public queryPlayersParams: ParamsHandler;

  public field = new ValidateFieldClass({
    labelName: {
      required: true,
      maxlength: 25,
    },
  });

  get$( id:number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.categoryApiService.getPartnerPartneridCategory({
      partnerId: partnerId,
    }).pipe(
      map( (res) => res?.filter( i => i.categoryId === id )?.[0] || undefined ),
    )
  }

  search$(params: SearchTreeApiRequest, partnerId = this.partnerApiService.currentPartnerId) {
    if (!params.partnerId) {
      params.partnerId = partnerId;
    }
    return this.categoryApiService.getPartnerPartneridCategorySearch(<any>params)
  }

  tree$(params: TreeApiRequest, partnerId = this.partnerApiService.currentPartnerId) {

    if (params['filter.sentence']) {
      return this.search$(params, partnerId) as Observable<RawCategoryTree>
    }

    if (!params.partnerId) {
      params.partnerId = partnerId;
    }

    return this.categoryApiService
        .getPartnerPartneridCategoryTree(<any>params)
        .pipe(
          map( (res) => {
            if ( typeof params['filter.rootId'] == 'undefined')
              res.categories = res?.categories?.filter(i => !i.category?.parentId ) || [];

            return res
          })
        );
  }

  create$(body: CategoryApi, partnerId = this.partnerApiService.currentPartnerId) {

    if (!body.partnerId) {
      body.partnerId = partnerId;
    }

    return this.categoryApiService.putPartnerPartneridCategoryCreate({
      partnerId,
      body
    })
  }

  update$(body: CategoryApi, partnerId = this.partnerApiService.currentPartnerId) {
    return this.categoryApiService.postPartnerPartneridCategoryCategoryid({
      partnerId,
      categoryId: <any>body.categoryId,
      body
    })
  }

  delete$(categoryId: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.categoryApiService.deletePartnerPartneridCategoryCategoryid({
      partnerId,
      categoryId,
    })
  }

}
