import { Injectable } from '@angular/core';

import { PartnerApiService } from '../partner-api/partner-api.service';
import {catchError, concatAll, concatMap, map, mergeMap, switchMap, toArray} from 'rxjs/operators';
import {concat, from, Observable, of, Subscriber, throwError} from 'rxjs';
import {
  CreateGoodPriceApi, GoodApi,
  GoodApiService as GoodApiServiceGen,
} from '../generated/Good';
import {ApiRequestHandlerClass} from "../../../class/api-request-handler.class";
import {LocalStorageService} from "../../localStorage/local-storage.service";
import {ParamsHandler} from "../../../class/ParamsHandler/Params.handler";
import {ValidateFieldClass} from "../../../class/validate-field.class";

@Injectable({
  providedIn: 'root',
})
export class GoodApiService extends ApiRequestHandlerClass {
  constructor(
    private partnerApiService: PartnerApiService,
    private goodApiService: GoodApiServiceGen,
    localStorageService: LocalStorageService
  ) {
    super();

    this.queryParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );

    this.queryPlayersParams = new ParamsHandler(
      {
        pager: {
          type: 'object',
          dropField: 'paging.offset',
          limitField: 'paging.limit',
          limit: 50,
        },
      },
      localStorageService
    );
  }

  public queryParams: ParamsHandler;

  public queryPlayersParams: ParamsHandler;

  public field = new ValidateFieldClass({
    labelName: {
      required: true,
      maxlength: 25,
    },
  });

  query$(params: {
    partnerId?: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }, partnerId = this.partnerApiService.currentPartnerId) {
    if (!params.partnerId) {
      params.partnerId = partnerId;
    }
    return this.goodApiService.getPartnerPartneridGood(<any>params);
  }

  category$(params: {
    partnerId?: number;
    categoryId: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }, partnerId = this.partnerApiService.currentPartnerId) {
    if (!params.partnerId) {
      params.partnerId = partnerId;
    }
    return this.goodApiService.getPartnerPartneridGoodCategoryid(<any>params);
  }

  get$(sku: string, partnerId = this.partnerApiService.currentPartnerId) {

    return this.goodApiService.getPartnerPartneridGoodSkuSku({
      sku: sku,
      partnerId: partnerId,
    });
  }

  getBySkus$(skus: string[] | string, partnerId = this.partnerApiService.currentPartnerId) {

    if (typeof skus === 'string') {
      skus = [skus];
    }

    return from(skus || [])
      .pipe(
        mergeMap( sku =>
          this.get$(sku, partnerId)
           .pipe( catchError( () => of(<GoodApi>{
             name: 'Товар с SKU: ' + sku,
             sku: sku,
             partnerId
           })))
        ),
        toArray()
      )

  }

  create$(body: GoodApi, partnerId = this.partnerApiService.currentPartnerId):Observable<GoodApi>{

    if ( !body.partnerId ) {
      body.partnerId = partnerId;
    }

    return <any>this.goodApiService.putPartnerPartneridGoodCreate({
      partnerId: body.partnerId,
      body
    }).pipe(
      switchMap(() => this.get$(body.sku, body.partnerId))
    )
  }

  update$(body: GoodApi, partnerId = this.partnerApiService.currentPartnerId) {

    if ( !body.partnerId ) {
      body.partnerId = partnerId;
    }

    return this.goodApiService.postPartnerPartneridGoodSkuSku({
      partnerId: body.partnerId,
      sku: body.sku,
      body
    }).pipe(
      switchMap(() => this.get$(body.sku, body.partnerId))
    )
  }

  delete$(sku: string, partnerId = this.partnerApiService.currentPartnerId) {

    return this.goodApiService.deletePartnerPartneridGoodSkuSku({
      sku, partnerId
    })

  }


  getPriceList$(sku: string, partnerId = this.partnerApiService.currentPartnerId) {
    return this.goodApiService.getPartnerPartneridGoodSkuPrice({
      sku: sku,
      partnerId: partnerId,
    })
  }

  updatePrice$(body: CreateGoodPriceApi,  sku: string, locationId: number, partnerId = this.partnerApiService.currentPartnerId) {
    return this.goodApiService.postPartnerPartneridGoodSkuLocationLocationidPrice({
      sku: sku,
      locationId: locationId,
      partnerId: partnerId,
      body: body,
    })
  }

}
