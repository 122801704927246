<div class="w-full mb-2"
     *ngIf="!isSearchState && isEditMode "
>
   <button class="btn btn-sm btn-success me-1"
           [disabled]="currentNode && !currentNode?.data?.category "
       (click)="createCategory()"
   >
     <i class="fas fa-folder me-2 fa-sm"></i>
     Создать категорию
   </button>

  <button class="btn btn-sm btn-success me-1"
          [disabled]="!currentNode || !currentNode?.data?.category"
          (click)="createGood()"
  >
    <i class="fas fa-tag me-2 fa-sm"></i>
    Создать товар
  </button>


  <button class="btn btn-sm btn-warning me-1 "
          [disabled]="!currentNode"
          (click)="editCategory()"
  >
    <i class="fas fa-i-cursor ms-1 me-1"></i>
  </button>

  <button class="btn btn-sm btn-danger me-1 float-end"
          [disabled]="!currentNode"
          (click)="deleteCategory()"
  >
    <i  class="fas fa-trash me-2 fa-sm"></i>
    Удалить
  </button>

</div>

<div class="d-flex align-items-stretch border-top">
  <div class="col">

    <tree-view
      [nodes]="tree"
      [showType]="showType"
      (getChildren)="getChildren($event)"
      [(selected)]="selected"
      (currentNodeChange)="currentNode = $event"


      [searchQuery]="searchQuery"
      (getNext)="getNext($event)"
      [selectOne]="selectOne"
      [editCallback]="editCallback"
      [isLoading]="isLoading"
    ></tree-view>

  </div>

  <div class="col b-l "
       *ngIf="(!!selected.size && !isEditMode) || isSearchState "
  >

    <selected-view
      [partnerId]="partnerId"
      [showType]="showType"
      [(selected)]="selected"

      [goodList] = "goodList"
      [searchQuery]="searchQuery"
      [selectOne]="selectOne"
      [editCallback]="editCallback"
    ></selected-view>

  </div>



</div>
