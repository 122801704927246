
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";

import {TreeViewComponent} from "../tree-view/tree-view.component";
import {ITreeNode} from "@circlon/angular-tree-component/lib/defs/api";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";
import {TreeNode} from "@circlon/angular-tree-component";
import {CategoryApiService} from "../../../../core/service/api/category-api/category-api.service";
import {GoodApiService} from "../../../../core/service/api/good-api/good-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  NgbdModalDialogSkuPriceListPopupComponent
} from "../../../../pages/directory/tree/components/sku-price-list-popup/skuPriceListPopup.service";
import {SkuAddPopupComponent} from "../sku-add-popup/sku-add-popup.component";
import {ConfirmActionService} from "../../../../core/service/confirmAction/confirmAction.service";


const LIMIT=30;
const SETTINGS_SORT_ID = 'directives.goodsPopup.sort';

@Component({
  selector: 'goods-tree-view',
  templateUrl: "./goods-tree-view.component.html",
  styleUrls: ['./goods-tree-view.component.scss' ],
  providers: [
    CategoryApiService
  ]
})
 export class GoodsTreeViewComponent implements OnInit, OnChanges {

    @Input() partnerId;
    @Input() showType;

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max : number | undefined;
    @Input() selectOne : boolean;
    @Input() dimension;
    @Input() isSkuHide;
    @Input() isShowArchiveSku;

    @Input()  searchQuery: string;

    @Input()  sortingField: string;
    @Output() sortingFieldChange = new EventEmitter();

    @Input() editCallback;

    get isEditMode() {
      return typeof this.editCallback === "function"
    };

    public tree:any[] = [];
    public goodList:any[] = [];

    public currentNode: TreeNode | undefined;

    public isLoading = false;

    @ViewChild(TreeViewComponent)
    private treeView: TreeViewComponent;

    constructor(
      private goodTreeGetterService: GoodTreeGetterService,
      private categoryApiService: CategoryApiService,
      private goodApiService: GoodApiService,
      private modalService: NgbModal,
      private confirmActionService: ConfirmActionService
    )
    {
    }

    ngOnInit() {
      this.reset();
    }


    ngOnChanges(changes: SimpleChanges) {

      if (changes['partnerId'] && !changes['partnerId'].firstChange) {
        this.reset();
        return;
      }

      if (changes['searchQuery'] && !changes['searchQuery'].firstChange) {
        this.reset();
        return;
      }

      if (changes['sortingField'] && !changes['sortingField'].firstChange) {

/*
        if (!!changes['sortingField'].currentValue) {
          UserSettingsStorage.set(SETTINGS_SORT_ID, changes['sortingField'].currentValue);
        }
*/

        if ( typeof changes['sortingField'].previousValue !== "undefined" )
          this.reset();

        return;
      }

    }

    get isSearchState() {
      return !!this.searchQuery
    }

    async getTree( filterRootId?, drop= 0, limit = LIMIT ): Promise<ITreeNode[]> {

      this.isLoading = typeof filterRootId === "undefined";

      if ( typeof this.sortingField === "undefined") {
/*        try {
          await UserSettingsStorage.get(SETTINGS_SORT_ID).then( i =>  {
            this.sortingField = i || 'none';
            this.sortingFieldChange.emit(this.sortingField);
          }, () => {
            this.sortingField = 'none';
          });
        } catch (e) {
          this.sortingField = 'none';
        }*/

        this.sortingField = 'none';
      }

      if (typeof this.partnerId === "undefined")
        return <any>undefined;

      if (this.isSearchState) {
        return this.goodTreeGetterService
          .getSearchTree(
            this.partnerId,
            this.searchQuery,
            filterRootId,
            this.sortingField === 'none' ? undefined : this.sortingField ,
            this.dimension
          )
          .then( res => {
            this.isLoading = false;
            return res;
          }, err => {
            this.isLoading = false;
            return err;
          })
          .then( this.parseSearchResult.bind(this));

      } else
        return this.goodTreeGetterService
            .getTree(
              this.partnerId,
              filterRootId,
              drop,
              limit,
              this.sortingField === 'none' ? undefined : this.sortingField,
              this.dimension
            )
            .then( res => {
              this.isLoading = false;
              return res;
            }, err => {
              this.isLoading = false;
              return err;
            })
            .then( this.parseResult.bind(this) );

    }

    private parseResult(result ): any[] {

      let callbackArray:any = [];
      result.categories?.forEach(
        category => {
          callbackArray.push({
            id          : category.category.categoryId,
            name        : category.category.name,
            category    :  category,
            hasChildren : !!category.categoryCount || !!category.goodCount,
            hasLimits   : !!Object.keys(category?.category?.limits || {}).length
          })
        }
      );

      if (!this.isSkuHide )
        result.goods.forEach(
          good => {
            callbackArray.push({
              id          : good.sku,
              name        : good.name,
              good        : good,
              hasChildren : false,
              hasLimits   : !!Object.keys(good?.limits || {}).length
            })
          }
        );

      return callbackArray;
    }

    private parseSearchResult(result ): ITreeNode[] {


      let callbackArray: any[] = [];

      let parseCatalogRecourse = (item, callbackArray:any = []) => {

        let childrenArray: any[]= []

        callbackArray.push({
          id          : item.category.categoryId,
          name        : item.category.name,
          category    : item,
          children    : childrenArray
        })

        if ( Array.isArray( item.categories ) && item.categories.length ) {
          item.categories?.forEach( subCat => {
            parseCatalogRecourse( subCat, childrenArray)
          })
          delete item.categories;
        }

        return callbackArray;
      }

      result?.tree?.forEach(
        item => {
          parseCatalogRecourse( item, callbackArray )
        }
      );

      this.goodList = [];
      if (!this.isSkuHide) {
        this.goodList = result.goods
                .filter( i => this.isShowArchiveSku || (!this.isShowArchiveSku && i.state !== 'archive' ) )
                .map( i => ({
                    id: i.sku,
                    label: i.name,
                    partnerId: i.partnerId,
                    dimension: i.dimension

                  })
                );
      }



      return callbackArray;
    }

    public getChildren( {node, resolve, reject} ) {

      if (this.isSearchState)
        return false;

      return this.getTree( node.id )
        .then( ( result : any ) => {

          if ( result.filter(i => !!i.good).length < LIMIT ) {
            node.data[TreeViewComponent.END_FLAG_ID] = true;
          }

          return result;

        })
        .then(resolve, reject)

    }

    public getNext(node: TreeNode) {

      node.data.$isLoading = true;

      this.getTree( node.id, node.data.children.filter( i => !!i.good).length )
        .then( (result: any[]) => {

          node.data.$isLoading = false;

          if ( !Array.isArray(result) )
            return

          let onlyGoods = result.filter(i => !!i.good);

          onlyGoods.forEach(i => {
            node.data.children.push(i)
          })

          node.data[TreeViewComponent.END_FLAG_ID] = !onlyGoods.length || onlyGoods.length < LIMIT;

          this.treeView?.redraw();
        }, () => {
          node.data.$isLoading = false;
        })

    }

    public async reset() {
      this.tree = await this.getTree();
      this.treeView?.updateTree(!!this.searchQuery);
    }

    private getPathForNode(node: TreeNode) {
      const resultArray: any = [];
      const id = node?.data?.id;

      let find = ( item ) => {
        if( !item.category || !item?.children?.length) return false
        const finded = item.children.find(i => i.id === id);
        if (finded) {
          resultArray.unshift(finded);
          return true
        }

        return item.children.some(i => {
          let res = find(i)
          if (res) {
            resultArray.unshift(i);
          }
          return res;
        } )
      }

      if (this.tree.some(i => i.id === id) ) {
        return [this.tree.find(i => i.id === id)];
      }

      this.tree.forEach(i => {
        if (find(i)) {
          resultArray.unshift(i);
        }
      });

      return resultArray;
    }

    createCategory() {

      const [
        partnerId,
        parentId
      ] = [
        this.currentNode?.data?.category?.category?.partnerId || undefined,
        this.currentNode?.data?.category?.category?.categoryId || undefined
      ]

      this.currentNode?.expand();

      this.categoryApiService.create$({
        partnerId: partnerId,
        parentId: parentId,
        name: "Новая категория",
        state: "active"
      }, partnerId)
        .subscribe(
          category => {

            if (!!this.currentNode) {

              this.currentNode.data.children = this.currentNode.data.children || [];
              this.currentNode?.data.children.unshift(
                {
                "id": category.categoryId,
                "name": category.name,
                "category": {
                  "category": category,
                  "categoryCount": 0,
                  "goodCount": 0
                },
                "hasChildren": true,
                "hasLimits": false,
                "children": [],
                [TreeViewComponent.EDIT_FLAG_ID]: true
              });

              this.getPathForNode(this.currentNode)?.forEach( i => {
                i.category = i.category || {};
                i.category.categoryCount ++;
                i.hasChildren = !!i.category.categoryCount || !!i.category.goodCount;
              })

              this.treeView?.redraw();
              this.currentNode?.expand();
            } else {
              const data = {
                "id": category.categoryId,
                "name": category.name,
                "category": {
                  "category": category,
                  "categoryCount": 0,
                  "goodCount": 0
                },
                "hasChildren": false,
                "hasLimits": false,
                "children": [],
                [TreeViewComponent.EDIT_FLAG_ID]: true
              };

              this.tree.unshift(data);
              this.treeView?.redraw();
            }

          }
        )
    }

    createGood() {

      if (!this.currentNode)
        return

      if (!!this.currentNode.data.category) {
        this.currentNode?.expand();
      }

      const modalRef = this.modalService.open(
        SkuAddPopupComponent,
        {
          size: 'md',
        }
      );

       modalRef.result.then( result => {

         const [
           partnerId,
           parentId
         ] = [
           this.currentNode?.data?.category?.category?.partnerId || undefined,
           this.currentNode?.data?.category?.category?.categoryId || undefined
         ]


         this.goodApiService.create$({
           partnerId: partnerId,
           categoryId: parentId,
           name: result.name,
           sku: result.sku,
           barcode: result.barcode || undefined,
           description: result.description || undefined,
         }, partnerId)
           .subscribe(
             good => {

               if (!!this.currentNode) {

                 this.currentNode.data.children = this.currentNode.data.children || [];
                 this.currentNode?.data.children.unshift(
                   {
                     "id": good.sku,
                     "name": good.name,
                     "good": good,
                     "hasChildren": false,
                     "hasLimits": false
                   });


                 this.getPathForNode(this.currentNode)?.forEach( i => {
                   i.category = i.category || {};
                   i.category.goodCount ++;
                   i.hasChildren = !!i.category.categoryCount || !!i.category.goodCount;
                 })

                 this.treeView?.redraw();
                 this.currentNode?.expand();

               }

             }
           )

       }, () => {});


    }

    editCategory() {
      if (!this.currentNode)
        return

      this.currentNode.data.$newName = this.currentNode.data.name;
      this.currentNode.data[TreeViewComponent.EDIT_FLAG_ID] = true;
    }

    deleteCategory() {
        if (!this.currentNode)
          return

      this.confirmActionService.confirm(
        $localize`:|@@goods-tree-view.are-you-sure-you-want-to-remove-the-label?:Вы уверены, что хотите удалить ` +
        (!!this.currentNode?.data?.category ? 'категорию ' : 'товар ') + this.currentNode?.data?.name + '?',
        () => {

          if (!this.currentNode)
            return;

          const id = this.currentNode.data.id;
          const path = this.getPathForNode(this.currentNode);

          if (!!this.currentNode?.data?.category) {

            this.categoryApiService.delete$(id, this.currentNode.data.partnerId)
              .subscribe(
                result => {
                  let node = path.pop();
                  let root = !!path?.length ? path[path?.length-1] : undefined;
                  const countCategoryForDelete = ( node?.category?.categoryCount || 0 )+ 1;

                  if (root) {
                    root.children = root.children.filter(i => i.id !== id);
                  } else {
                    this.tree = this.tree.filter(i => i.id !== id);
                  }

                  path?.forEach( i => {
                    i.category = i.category || {};
                    i.category.categoryCount  = i.category.categoryCount - countCategoryForDelete >=0 ? i.category.categoryCount - countCategoryForDelete : 0;
                    i.hasChildren = !!i.category.categoryCount || !!i.category.goodCount;
                  })

                  this.currentNode = undefined;
                  this.treeView?.redraw();
                }
              )
          } else {

            this.goodApiService.delete$(id, this.currentNode.data.partnerId)
              .subscribe( result => {

                let node = path.pop();
                let root = !!path?.length ? path[path?.length-1] : undefined;
                const countGoodsForDelete = ( node?.category?.goodCount || 0 ) + 1;

                if (root) {
                  root.children = root.children.filter(i => i.id !== id);
                } else {
                  this.tree = this.tree.filter(i => i.id !== id);
                }

                path?.forEach( i => {
                  i.category = i.category || {};
                  i.category.goodCount  = i.category.goodCount - countGoodsForDelete >=0 ? i.category.goodCount - countGoodsForDelete : 0;
                  i.hasChildren = !!i.category.categoryCount || !!i.category.goodCount;
                })

                this.currentNode = undefined;
                this.treeView?.redraw();

              })
          }
        }
      );

    }

}
