import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ITreeOptions, TreeComponent, TreeNode} from "@circlon/angular-tree-component";
import {VIEW_TYPES} from "../../goods-tree.variables";
import {CategoryApiService} from "../../../../core/service/api/category-api/category-api.service";
import {GoodApiService} from "../../../../core/service/api/good-api/good-api.service";
import {switchMap} from "rxjs";

const INTERMEDIATE_FIELD = "$INTERMEDIATE$";

@Component({
  selector: 'tree-view',
  templateUrl: "./tree-view.component.html",
  styleUrls: ['./tree-view.component.scss' ],
  providers: [
    CategoryApiService
  ]
})
 export class TreeViewComponent implements  OnInit, OnChanges{

    @Input() nodes;
    @Input() showType;
    @Output() getChildren = new EventEmitter();

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Output() currentNodeChange = new EventEmitter();

    @Output() getNext = new EventEmitter();

    @Input() searchQuery: string;
    @Input() selectOne = false

    @Input() editCallback;

    @Input() isLoading = false;

    get isEditMode() {
      return typeof this.editCallback === "function"
    };

    @ViewChild(TreeComponent)
    private treeComponent: TreeComponent;

    public options:ITreeOptions = {}

    static END_FLAG_ID = '$isEndedGoods';
    static EDIT_FLAG_ID = '$isEditNode';

    public currentNode: TreeNode | undefined;

  constructor(
    private categoryApiService: CategoryApiService,
    private goodApiService: GoodApiService
  )
    {}

    get isSearchState() {
      return !!this.searchQuery;
    }

    ngOnInit() {
      this.initOptions()
    }

    ngOnChanges(changes: SimpleChanges) {
    /*  if (changes['nodes'])
        this.updateTree();*/
    }

    public isCheckboxShow(node) {
        if (!!this.selectOne || this.isEditMode)
          return  false;

        if ( (this.showType === VIEW_TYPES.CATEGORY) && this.isNodeFolder(node) ) {
          return true;
        }

        if ( (this.showType === VIEW_TYPES.SKU) && this.isNodeSku(node) ) {
          return true;
        }

      return false;
    }

    public isRadioShow(node) {
      if (!this.selectOne || this.isEditMode)
        return  false;

      if ( (this.showType === VIEW_TYPES.CATEGORY) && this.isNodeFolder(node) ) {
        return true;
      }

      if ( (this.showType === VIEW_TYPES.SKU) && this.isNodeSku(node) ) {
        return true;
      }

      return false;
    }

    private initOptions() {

      this.options.getChildren = (node:TreeNode) =>
        new Promise( (resolve, reject) => {
          this.getChildren.emit({
            resolve,
            reject,
            node
          });
        })

      this.updateTree();
    }

    public updateTree( expandAll = false ) {

      if (!expandAll) {
        this.treeComponent?.treeModel?.collapseAll();
      }

      this.redraw();

      if (expandAll) {
        setTimeout(IO => {
          this.treeComponent?.treeModel?.expandAll();
        } )
      }

    }

    public redraw() {
      this.treeComponent?.treeModel?.update();
    }

    isNodeFolder(node) {
      return !!node?.data?.category
    }

    isNodeSku(node) {
      return !!node?.data?.good
    }

    isLastNode(node: TreeNode) {

      if (!this.isNodeSku(node))
        return false;

      if (node?.parent?.getLastChild() !== node)
        return false;

      if (!!node?.parent?.data?.$isEndedGoods)
        return false

     //  let total = node?.parent?.data?.category?.goodCount || 0;


      return true;
      /*
      if (!total)
        return false;

      let count = node?.parent?.children?.filter( i => this.isNodeSku(i)).length;

      return total !== count;*/
    }

    isEqualNodes(node1: TreeNode | undefined, node2: TreeNode | undefined) {
      return node1?.id === node2?.id;
    }

    isEditNode(node) {
      return !!node?.data?.[TreeViewComponent.EDIT_FLAG_ID];
    }

    resetIntermediateCacheForParent(node){

      if (this.isIndeterminateEnabled(node)) {
        delete node.data[INTERMEDIATE_FIELD];
        let parent = node?.parent;
        while ( !!parent ) {
          delete parent.data[INTERMEDIATE_FIELD];
          parent = parent?.parent;
        }

      }

    }

    changeSelected(node: TreeNode, $event?) {


      switch (true) {

        case this.isSubIndeterminate(node):
          if (this.changeSubIndeterminateSelected(node)) {

            if ($event && !!$event?.target) {
              $event.target.checked = false;
            }

          }
          break;

        // @ts-ignore
        case this.isIndeterminate(node):
          this.disableSubFolder(node);

        case this.isNodeFolder(node):
          this.changeSelectedFolder(node);
          break

        default:
          if (this.selected.has(node.id)) {
            this.selected.delete(node.id)
          } else {

            if (this.selectOne)
              this.selected.clear();

            this.selected.add(node.id);
          }
      }

      this.resetIntermediateCacheForParent(node);
      this.selectedChange.emit(this.selected);

    }

    changeSubIndeterminateSelected(node: TreeNode) {

      this.selected.delete(node?.id);

      if (!node?.parent )
        return false;

      if (!this.isSubIndeterminate(node?.parent) && !this.isChecked(node?.parent))
        return false

      this.selected.delete(node?.parent?.id);

      node.parent.children.forEach( i => {
        if (i === node || !this.isNodeFolder(i))
          return;

        this.selected.add(i.id);
      })

      this.changeSubIndeterminateSelected(node.parent);

      return true;
    }

    disableSubFolder( node: TreeNode ): void{

      let disableSubFolderiterate = (subNode: TreeNode): void => {

          if (!this.isNodeFolder(subNode))
            return ;

          if (subNode!==node) {
            this.selected.delete(subNode.id);
            delete node.data[INTERMEDIATE_FIELD];
          }

          subNode?.children?.forEach( i => {
            disableSubFolderiterate(i);
          });
        }

      disableSubFolderiterate(node);
    }

    changeSelectedFolder(node: TreeNode) {

      delete node.data[INTERMEDIATE_FIELD];

      if (this.selected.has(node.id)) {
        this.selected.delete(node.id)
      } else {
        if (this.selectOne)
          this.selected.clear();

        this.selected.add(node.id);
      }

      this.disableSubFolder(node);

    }

    editClick(node: TreeNode) {

      if (!this.editCallback)
        return;

      this.editCallback({
        type: this.isNodeSku(node) ? VIEW_TYPES.SKU : VIEW_TYPES.CATEGORY,
        id: node.id
      })

    }

    getIcon(node: TreeNode) {

      switch (true) {
        /*case node.loading :
          return 'fas fa-spinner fa-spin ';*/
        case this.isNodeFolder( node ) && (node.data.category.goodCount > 0 || node.data.category.categoryCount > 0)  :
          return 'fas text-success fa-folder' + ((node.isExpanded || this.isSearchState) ? '-open ' : '' );
        case this.isNodeFolder( node ) && (node.data.category.goodCount == 0 && node.data.category.categoryCount == 0) :
          return 'far text-muted fa-folder' + ((node.isExpanded || this.isSearchState) ? '-open ' : '' );
        case this.isNodeSku(node):
          return (node.data?.good?.dimension === 'weight' ? 'fa-balance-scale fas ' : 'fa-tag fas ') +
            (!!node.data?.good?.imageFileId ? 'text-info ' : 'text-muted ' );
      }

      return '';
    };

    getUniqNodeName(node: TreeNode) {
      return escape(`goodPopupInput_${this.showType}_${node.id}`);
    }

    isChecked( node: TreeNode ) {
      return this.selected.has(node.id);
    }

    isIndeterminateEnabled(node: TreeNode) {
      return this.showType === VIEW_TYPES.CATEGORY && this.isNodeFolder(node);
    }

    isIndeterminate(node: TreeNode) {

      if ( !this.isIndeterminateEnabled(node) || this.isChecked(node) )
        return false;

      return node?.children?.some( i => {
      /*  if ( typeof i.data[INTERMEDIATE_FIELD] !== "undefined" )
          return i.data[INTERMEDIATE_FIELD];

        i.data[INTERMEDIATE_FIELD] = this.isChecked(i) || this.isIndeterminate(i) || false;
        return i.data[INTERMEDIATE_FIELD]*/
        return this.isChecked(i) || this.isIndeterminate(i) || false;
      });

    }

    isSubIndeterminate(node) {

      if ( !this.isIndeterminateEnabled(node) || this.isChecked(node)  )
        return false;


      if ( node?.parent && ( this.isChecked(node?.parent) || this.isSubIndeterminate(node?.parent)) ) {
        return true
      }

      return false;

    }

    expandNode(node: TreeNode) {
      node.toggleExpanded();
      this.selectNode(node);
    }

    selectNode(node: TreeNode, withUnselect = false) {


        if (!!this.currentNode) {
          delete this.currentNode?.data?.[TreeViewComponent.EDIT_FLAG_ID];
        }

        if (withUnselect) {
          this.currentNode = this.isEqualNodes(this.currentNode, node) ? undefined : node;
        } else {
          this.currentNode =/* this.isEqualNodes(this.currentNode, node) ? undefined : */node;
        }
        this.currentNodeChange.emit(this.currentNode);

    }

    changeNodeKeyDown($event: KeyboardEvent, node) {

      switch (true) {
        case $event.keyCode === 13:
          $event.preventDefault();
          return this.updateNodeName(node);
        case $event.keyCode === 27:
          $event.preventDefault();
          delete node.data[TreeViewComponent.EDIT_FLAG_ID];
          return ; //this.resetNode(node);
      }

      return;
    }

    updateNodeName(node) {

      delete node.data[TreeViewComponent.EDIT_FLAG_ID];

      if (!node.data?.$newName)
        return;

      if (node.data?.category)
        return this.categoryApiService
          .update$({
            name: node.data?.$newName,
            parentId: node.data.category.category.parentId,
            partnerId: node.data.category.category.partnerId,
            categoryId: node.data.id,
            state: node.data.category.category.state,
          }, node.data.category.category.partnerId)
          .toPromise()
          .then(() => {

            node.data.category.category.name = node.data?.$newName;
            node.data.name = node.data?.$newName;
            delete node.data.$isEditing;
            delete node.data.$newName;
            this.selectNode(node);
          });

      if (node.data?.good)

        return this.goodApiService
          .get$(node.data.id, node.data?.good?.partnerId)
          .pipe(
            switchMap(
              good => {
                good.name = node.data?.$newName;
                return this.goodApiService.update$(good)
              }
            )
          )
          .toPromise()
          .then(() => {

            node.data.good.name = node.data?.$newName;
            node.data.name = node.data?.$newName;
            delete node.data.$isEditing;
            delete node.data.$newName;

          });

      return Promise.resolve();
    }

}

