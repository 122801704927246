import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CatalogApiService } from './api/catalog-api/catalog-api.service';
import { PartnerApiService } from './api/partner-api/partner-api.service';
import { PlaylistApiService } from './api/playlist-api/playlist-api.service';
import { StatesChangerService } from './statesChanger/statesChanger.service';
import { FileApiService } from './api/file-api/file-api.service';

import { GeneratedApiModule } from './api/generated/generated-api.module';
import { ConfirmActionService } from './confirmAction/confirmAction.service';
import { PlayerApiService } from './api/player-api/player-api.service';
import { TemplateApiService } from './api/template-api/template-api.service';
import {
  LocationPopupService,
  NgbdModalDialogLocationPopupComponent,
} from './locationPopup/locationPopup.service';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CorePipesModule } from '../pipe/core-pipes.module';
import { UserApiService } from './api/user-api/user-api.service';
import { PermissionsService } from './permissions/permissions.service';
import { LocalStorageService } from './localStorage/local-storage.service';
import { FileSelectorModule } from './files-selector/file-selector.module';
import { CoreComponentsModule } from '../components/core.components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  NgbdModalDialogPlatformPopupComponent,
  PlatformPopupService,
} from './platformPopup/platformPopup.service';
import {
  LabelsBWPopupService,
  NgbdModalDialoglabelsBWPopupPopupComponent,
} from './labelsBWPopup/labelsBWPopup.service';
import {
  PlayersPopupComponent,
  PlayersPopupService,
} from './playersPopup/playersPopup.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import {
  DeviceTypePopupService,
  NgbdModalDialogDeviceTypePopupComponent,
} from './deviceTypePopup/deviceTypePopup.service';
import {ResponsiveTableModule} from "../components/responsive-table/responsive-table.module";
import {
  LocationGroupPopupService,
  NgbdModalDialogLocationGroupPopupComponent
} from "./locationGroupPopup/locationGroupPopup.service";
import {GoodsTreeModule} from "../../modules/goods/goods-tree.module";

@NgModule({
  declarations: [
    NgbdModalDialogLocationPopupComponent,
    NgbdModalDialogLocationGroupPopupComponent,
    NgbdModalDialogPlatformPopupComponent,
    NgbdModalDialogDeviceTypePopupComponent,
    NgbdModalDialoglabelsBWPopupPopupComponent,
    PlayersPopupComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    GeneratedApiModule,
    FormsModule,
    FontAwesomeModule,
    CorePipesModule,
    CoreComponentsModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    ResponsiveTableModule,
    GoodsTreeModule,
  ],
  providers: [
    CatalogApiService,
    FileApiService,
    PartnerApiService,
    PlayerApiService,
    PlaylistApiService,
    ConfirmActionService,
    LocationPopupService,
    LocationGroupPopupService,
    TemplateApiService,
    UserApiService,
    StatesChangerService,
    PermissionsService,
    LocalStorageService,
    PlatformPopupService,
    LabelsBWPopupService,
    PlayersPopupService,
    DeviceTypePopupService,
  ],
})
export class CoreServicesModule {}
