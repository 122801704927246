/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateGoodPriceApi } from '../models/create-good-price-api';
import { GetGoodPriceApi } from '../models/get-good-price-api';
import { GoodApi } from '../models/good-api';

@Injectable({
  providedIn: 'root',
})
export class GoodApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPartnerPartneridGood
   */
  static readonly GetPartnerPartneridGoodPath = '/partner/{partnerId}/good';

  /**
   * Список товаров по указанному партнёру
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridGood()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGood$Response(params: {
    partnerId: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<Array<GoodApi>>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.GetPartnerPartneridGoodPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.query('sort', params.sort, {});
      rb.query('drop', params.drop, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GoodApi>>;
      })
    );
  }

  /**
   * Список товаров по указанному партнёру
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridGood$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGood(params: {
    partnerId: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }): Observable<Array<GoodApi>> {

    return this.getPartnerPartneridGood$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GoodApi>>) => r.body as Array<GoodApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridGoodCategoryid
   */
  static readonly GetPartnerPartneridGoodCategoryidPath = '/partner/{partnerId}/good/{categoryId}';

  /**
   * Список товаров для указанной категории
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridGoodCategoryid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodCategoryid$Response(params: {
    partnerId: number;
    categoryId: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<Array<GoodApi>>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.GetPartnerPartneridGoodCategoryidPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('categoryId', params.categoryId, {});
      rb.query('sort', params.sort, {});
      rb.query('drop', params.drop, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GoodApi>>;
      })
    );
  }

  /**
   * Список товаров для указанной категории
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridGoodCategoryid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodCategoryid(params: {
    partnerId: number;
    categoryId: number;
    sort?: string;
    drop?: number;
    limit?: number;
  }): Observable<Array<GoodApi>> {

    return this.getPartnerPartneridGoodCategoryid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GoodApi>>) => r.body as Array<GoodApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridGoodSkuSku
   */
  static readonly GetPartnerPartneridGoodSkuSkuPath = '/partner/{partnerId}/good/sku/{sku}';

  /**
   * Данные по указанному товару
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridGoodSkuSku()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodSkuSku$Response(params: {
    partnerId: number;
    sku: string;
  }): Observable<StrictHttpResponse<GoodApi>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.GetPartnerPartneridGoodSkuSkuPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoodApi>;
      })
    );
  }

  /**
   * Данные по указанному товару
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridGoodSkuSku$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodSkuSku(params: {
    partnerId: number;
    sku: string;
  }): Observable<GoodApi> {

    return this.getPartnerPartneridGoodSkuSku$Response(params).pipe(
      map((r: StrictHttpResponse<GoodApi>) => r.body as GoodApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridGoodSkuSku
   */
  static readonly PostPartnerPartneridGoodSkuSkuPath = '/partner/{partnerId}/good/sku/{sku}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridGoodSkuSku()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridGoodSkuSku$Response(params: {
    partnerId: number;
    sku: string;

    /**
     * Изменить указанный товар
     */
    body: GoodApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.PostPartnerPartneridGoodSkuSkuPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridGoodSkuSku$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridGoodSkuSku(params: {
    partnerId: number;
    sku: string;

    /**
     * Изменить указанный товар
     */
    body: GoodApi
  }): Observable<void> {

    return this.postPartnerPartneridGoodSkuSku$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridGoodSkuSku
   */
  static readonly DeletePartnerPartneridGoodSkuSkuPath = '/partner/{partnerId}/good/sku/{sku}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridGoodSkuSku()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridGoodSkuSku$Response(params: {
    partnerId: number;
    sku: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.DeletePartnerPartneridGoodSkuSkuPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridGoodSkuSku$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridGoodSkuSku(params: {
    partnerId: number;
    sku: string;
  }): Observable<void> {

    return this.deletePartnerPartneridGoodSkuSku$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPartnerPartneridGoodSkuPrice
   */
  static readonly GetPartnerPartneridGoodSkuPricePath = '/partner/{partnerId}/good/{sku}/price';

  /**
   * Список цен на товар
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridGoodSkuPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodSkuPrice$Response(params: {
    partnerId: number;
    sku: string;
  }): Observable<StrictHttpResponse<Array<GetGoodPriceApi>>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.GetPartnerPartneridGoodSkuPricePath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetGoodPriceApi>>;
      })
    );
  }

  /**
   * Список цен на товар
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridGoodSkuPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridGoodSkuPrice(params: {
    partnerId: number;
    sku: string;
  }): Observable<Array<GetGoodPriceApi>> {

    return this.getPartnerPartneridGoodSkuPrice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetGoodPriceApi>>) => r.body as Array<GetGoodPriceApi>)
    );
  }

  /**
   * Path part for operation putPartnerPartneridGoodCreate
   */
  static readonly PutPartnerPartneridGoodCreatePath = '/partner/{partnerId}/good/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridGoodCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridGoodCreate$Response(params: {
    partnerId: number;

    /**
     * Создать товар
     */
    body: GoodApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.PutPartnerPartneridGoodCreatePath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridGoodCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridGoodCreate(params: {
    partnerId: number;

    /**
     * Создать товар
     */
    body: GoodApi
  }): Observable<void> {

    return this.putPartnerPartneridGoodCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postPartnerPartneridGoodSkuLocationLocationidPrice
   */
  static readonly PostPartnerPartneridGoodSkuLocationLocationidPricePath = '/partner/{partnerId}/good/{sku}/location/{locationId}/price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridGoodSkuLocationLocationidPrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridGoodSkuLocationLocationidPrice$Response(params: {
    partnerId: number;
    sku: string;
    locationId: number;

    /**
     * Установить цену на товар для данной локации
     */
    body: CreateGoodPriceApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.PostPartnerPartneridGoodSkuLocationLocationidPricePath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
      rb.path('locationId', params.locationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridGoodSkuLocationLocationidPrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridGoodSkuLocationLocationidPrice(params: {
    partnerId: number;
    sku: string;
    locationId: number;

    /**
     * Установить цену на товар для данной локации
     */
    body: CreateGoodPriceApi
  }): Observable<void> {

    return this.postPartnerPartneridGoodSkuLocationLocationidPrice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridGoodSkuLocationLocationidPrice
   */
  static readonly DeletePartnerPartneridGoodSkuLocationLocationidPricePath = '/partner/{partnerId}/good/{sku}/location/{locationId}/price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridGoodSkuLocationLocationidPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridGoodSkuLocationLocationidPrice$Response(params: {
    partnerId: number;
    sku: string;
    locationId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GoodApiService.DeletePartnerPartneridGoodSkuLocationLocationidPricePath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('sku', params.sku, {});
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridGoodSkuLocationLocationidPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridGoodSkuLocationLocationidPrice(params: {
    partnerId: number;
    sku: string;
    locationId: number;
  }): Observable<void> {

    return this.deletePartnerPartneridGoodSkuLocationLocationidPrice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
