import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../goods-tree.variables";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GoodsTreeComponent} from "../components/goods-tree/goods-tree.component";

export type goodSelectorParams = {
  forPartner?: number | string,
  checkOne?: boolean | string,
  linkOne?: boolean | string,
  showCategories?: string[] | string,
  initSelectedItems?: any,
  initPartner?: number | string,
  hideByType?: any,
  filterByParams?: any,
  max?: number,
  showArchive?: boolean | string,
}

export type legacyDirectiveParams = goodSelectorParams & {
  resultDirective: (result: any) => any | {},
}

@Injectable({
  providedIn: "root",
})
export class GoodsSelectorService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public open(options: goodSelectorParams) {
    const modalRef = this.modalService.open(
      GoodsTreeComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    return modalRef.result;

  }

  public legacyPopup(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      GoodsTreeComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    modalRef.result.then( result => {

      this.legacyResult(result, options.resultDirective)

    }, cancal => {})

  }

  private legacyResult(result, resultDirective) {

    if (typeof resultDirective === "function") {
      resultDirective(result);
    }

    if (typeof resultDirective === "object") {

      Object.keys(resultDirective).forEach( key => {
        delete resultDirective[key];
      })

      Object.assign(resultDirective, result);
    }

  }

  private legacySetOptions(modalInstance: GoodsTreeComponent, options: legacyDirectiveParams | goodSelectorParams) {

    // выбранные категории
    if (!!options?.showCategories) {
      options.showCategories = typeof options.showCategories === "string" ? [options.showCategories] : options.showCategories;
//      options.showCategories = options.showCategories.map( item => item === "goodGroups" ? VIEW_TYPES.GOODGROUP : item )
      modalInstance.setViewList(<VIEW_TYPES[]>options.showCategories);
    }

    // Выбранный VIEW по умолчанию
    if (!!options?.initSelectedItems &&
          options?.initSelectedItems?.type &&
          Object.values(VIEW_TYPES).includes( this.legacyConvertTypes(options?.initSelectedItems?.type) )
    ) {
      modalInstance.changeView(
        this.legacyConvertTypes(options?.initSelectedItems?.type)
      );
    }



    // Выбранный партнёр для старта
    if (!!options?.initPartner) {
      let partnerId = parseInt(<any>options?.initPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId );
    }

    // Форма только для этого партнёра
    if (!!options?.forPartner) {
      let partnerId = parseInt(<any>options?.forPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId, false );
    }

    // Максимальное количество
    if ( typeof options?.max !== "undefined") {
      modalInstance.setMaximumLimit( options.max );
    }

    // Максимальное количество
    if ( typeof options?.filterByParams !== "undefined") {
      modalInstance.setParamsForQuery( options.filterByParams );
    }

    // Скрыть SKU из дерева
    if ( Array.isArray( options?.hideByType ) && options.hideByType.includes( VIEW_TYPES.SKU )) {
      modalInstance.setHideSku();
    }

    // Показывать архивные в найденных SKU из дерева
    if ( !!options?.showArchive ) {
      modalInstance.setShowArchiveSku();
    }

    if ( !!options?.linkOne || !!options?.checkOne ) {
      modalInstance.setOneSelect();
    }

  }

  private legacyConvertTypes( inputType ) {

    inputType = inputType.toLowerCase();

    switch (true) {
/*
      case inputType === 'goodgroups' : return VIEW_TYPES.GOODGROUP;
*/
      case inputType === 'goods'      : return VIEW_TYPES.SKU;
      case inputType === 'categories' : return VIEW_TYPES.CATEGORY;
/*
      case inputType === 'brands'     : return VIEW_TYPES.BRAND;
*/
    }

    return inputType;
  }


}
