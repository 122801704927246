export enum VIEW_TYPES  {
  'SKU' = 'sku',
  'CATEGORY' = 'category',/*
  'BRAND' = 'brand',
  'GOODGROUP' = 'custom',
  'GOODGROUP_SKU' = 'groupId',
  'FAVOURITE' = 'favourite'*/
}

export const VIEW_TYPES_NAMES = {
  [VIEW_TYPES.SKU] : 'SKU',
  [VIEW_TYPES.CATEGORY] : 'Категория',/*
  [VIEW_TYPES.BRAND] : 'Бренд',
  [VIEW_TYPES.GOODGROUP] : 'Список',
  [VIEW_TYPES.GOODGROUP_SKU] : 'Список (по коду списка)',
  [VIEW_TYPES.FAVOURITE] : 'Предпочитаемые товары'*/

}
