import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse, HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '@uirouter/core';

export const skipAuthContext  = new HttpContextToken<boolean>(() => true);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private $state: StateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = req.clone();

    return next.handle(authReq).pipe(
      tap(
        event => {
          /*  if (event instanceof HttpResponse)
            console.log('Server response', event);*/
        },
        err => {
          if (authReq?.context?.has(skipAuthContext)) {
            return;
          }

          if (err instanceof HttpErrorResponse) {
            if (
              err.status === 401 ||
              (err.status === 400 &&
                err.error &&
                (err.error?.includes('Invalid value for: cookie DS_AUTH_SESS_ID') ||
                  err.error.includes('Decrypt error with cookie')))
            ) {
              (<any>err).stopPopupError && (<any>err).stopPopupError();

              setTimeout(() => {
                if (!!(<any>err)?.stopLoginRedirect) return;

                this.$state?.transition?.abort();
                this.$state.transitionTo('login', undefined, {
                  supercede: false,
                });
              });
            }
          }
        }
      )
    );
  }
}
