
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";

import {GoodTreeGetterService} from "../../service/good-tree-getter.service";
import {VIEW_TYPES, VIEW_TYPES_NAMES} from "./../../goods-tree.variables";

@Component({
  selector: 'manual-good-input',
  templateUrl: "./manual-good-input.component.html",
  styleUrls: ['./manual-good-input.component.scss' ],
  providers: [
  ]
})
 export class ManualGoodInputComponent implements OnInit, OnChanges {

    @Input() partnerId;

    @Input() selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max:number | undefined;
    @Input() selectOne:boolean;

    @Input() currentView;

    public inputForAdd: string | undefined = '';
    public inputError = ''


    public VIEW_TYPES = VIEW_TYPES;

    constructor(
      private goodTreeGetterService: GoodTreeGetterService,
    )
    {
    }

    ngOnInit() {
      this.inputError = '';
    }

    ngOnChanges(changes: SimpleChanges) {

    }


    addNewItem() {

      if (!this.inputForAdd)
        return;

      this.goodTreeGetterService
        .getIdByType(this.inputForAdd, this.partnerId, this.currentView)
        .then( id => {

            if (this.selectOne && !this.selected.has(id))
              this.selected.clear();

            this.selected.add(id);

            this.inputForAdd = undefined;

        }, result => {

          switch (this.currentView) {

            case VIEW_TYPES.SKU:
              return this.inputError = 'SKU не найдено'

            case VIEW_TYPES.CATEGORY:
              return this.inputError = 'Категория не найдена'
          }

          return this.inputError = 'Не найдено'

        })

    }

  getPlaceholder() {
    switch (this.currentView) {

      case VIEW_TYPES.SKU:
        return  'Введите SKU'

      case VIEW_TYPES.CATEGORY:
        return  'Введите ID категории'

    }

    return  ''
  }


}
