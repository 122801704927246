import { PERMISSION_TYPES } from './permissions.enum';
import { UserRoleEnum } from '../api/user-api/user-api.service';
import { APP_ROUTES } from '../../../../app-routes.variables';

export const default_tree = {
  schedule: {
    api: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },

    player: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },
  },

  storage: {
    file: {
      api: {
        [PERMISSION_TYPES.CREATE]: true,
        [PERMISSION_TYPES.UPDATE]: true,
        [PERMISSION_TYPES.DELETE]: true,
      },
    },

    catalog: {
      api: {
        [PERMISSION_TYPES.CREATE]: true,
        [PERMISSION_TYPES.UPDATE]: true,
        [PERMISSION_TYPES.DELETE]: true,
      },
    },
  },

  templates: {
    api: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },
  },

  playlist: {
    api: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },
  },

  platform: {
    [PERMISSION_TYPES.ENABLED]: true,
    [PERMISSION_TYPES.CREATE]: true,
    [PERMISSION_TYPES.UPDATE]: true,
    [PERMISSION_TYPES.DELETE]: true,

    api: {
      downloadFile: {
        [PERMISSION_TYPES.ENABLED]: false,
      },
    },
  },

  label: {
    [PERMISSION_TYPES.ENABLED]: true,
    [PERMISSION_TYPES.CREATE]: true,
    [PERMISSION_TYPES.UPDATE]: true,
    [PERMISSION_TYPES.DELETE]: true,
  },

  locations: {
    [PERMISSION_TYPES.ENABLED]: true,
    api: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },
  },

  locationsGroup: {
    [PERMISSION_TYPES.ENABLED]: true,
    api: {
      [PERMISSION_TYPES.CREATE]: true,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: true,
    },
  },

  player: {
    [PERMISSION_TYPES.ENABLED]: true,
    [PERMISSION_TYPES.CREATE]: true,
    [PERMISSION_TYPES.UPDATE]: true,
    [PERMISSION_TYPES.DELETE]: true,

    api: {
      link: {
        [PERMISSION_TYPES.ENABLED]: false,
      },
    },
  },

  device: {
    [PERMISSION_TYPES.ENABLED]: true,
    [PERMISSION_TYPES.CREATE]: true,
    [PERMISSION_TYPES.UPDATE]: true,
    [PERMISSION_TYPES.DELETE]: true,

    api: {
      link: {
        [PERMISSION_TYPES.ENABLED]: false,
      },
    },
  },

  users: {
    [PERMISSION_TYPES.URL]: APP_ROUTES['root.users.**'].name,

    [PERMISSION_TYPES.ENABLED]: true,
    [PERMISSION_TYPES.CREATE]: true,
    [PERMISSION_TYPES.UPDATE]: true,
    [PERMISSION_TYPES.DELETE]: true,

    role: {
      [PERMISSION_TYPES.UPDATE]: true,
    },
  },

  journal: {
    [PERMISSION_TYPES.URL]: APP_ROUTES['root.journal.**'].name,

    [PERMISSION_TYPES.ENABLED]: true,
  },

  information: {
    [PERMISSION_TYPES.URL]: APP_ROUTES['root.information.**'].name,

    [PERMISSION_TYPES.ENABLED]: true,
  },

  settings: {
    [PERMISSION_TYPES.URL]: APP_ROUTES['root.settings.**'].name,

    [PERMISSION_TYPES.ENABLED]: false,
  },
};

export const role_tree = {
  [UserRoleEnum.observer]: {
    schedule: {
      api: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },

      player: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },
    },

    playlist: {
      api: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },
    },

    locations: {
      api: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },
    },

    locationsGroup: {
      api: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },
    },

    storage: {
      file: {
        api: {
          [PERMISSION_TYPES.CREATE]: false,
          [PERMISSION_TYPES.UPDATE]: false,
          [PERMISSION_TYPES.DELETE]: false,
        },
      },

      catalog: {
        api: {
          [PERMISSION_TYPES.CREATE]: false,
          [PERMISSION_TYPES.UPDATE]: false,
          [PERMISSION_TYPES.DELETE]: false,
        },
      },
    },

    templates: {
      api: {
        [PERMISSION_TYPES.CREATE]: false,
        [PERMISSION_TYPES.UPDATE]: false,
        [PERMISSION_TYPES.DELETE]: false,
      },
    },

    platform: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    player: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    device: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    users: {
      [PERMISSION_TYPES.ENABLED]: false,
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,

      role: {
        [PERMISSION_TYPES.UPDATE]: false,
      },
    },

    journal: {
      [PERMISSION_TYPES.ENABLED]: false,
    },

    information: {
      [PERMISSION_TYPES.ENABLED]: false,
    },
  },

  [UserRoleEnum.user]: {
    platform: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    player: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    device: {
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: false,
      [PERMISSION_TYPES.DELETE]: false,
    },

    users: {
      [PERMISSION_TYPES.ENABLED]: true,
      [PERMISSION_TYPES.CREATE]: false,
      [PERMISSION_TYPES.UPDATE]: true,
      [PERMISSION_TYPES.DELETE]: false,

      role: {
        [PERMISSION_TYPES.UPDATE]: false,
      },
    },
  },

  [UserRoleEnum.admin]: {
    platform: {
      api: {
        downloadFile: {
          [PERMISSION_TYPES.ENABLED]: true,
        },
      },
    },

    player: {
      api: {
        link: {
          [PERMISSION_TYPES.ENABLED]: true,
        },
      },
    },
  },

  [UserRoleEnum.superuser]: {
    platform: {
      api: {
        downloadFile: {
          [PERMISSION_TYPES.ENABLED]: true,
        },
      },
    },

    player: {
      api: {
        link: {
          [PERMISSION_TYPES.ENABLED]: true,
        },
      },
    },

    device: {
      api: {
        link: {
          [PERMISSION_TYPES.ENABLED]: true,
        },
      },
    },

    settings: {
      [PERMISSION_TYPES.ENABLED]: true,
    },
  },
};
