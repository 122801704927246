<div class="padder pt-2 ps-4">

  <ng-container *ngIf="!isLoading">
    <tree-root  [focused]="true" [nodes]="nodes"  [options]="options">
    <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">

      <div class="tree-row" data-testid="treeRow">

        <div class="tree-row_line"></div>

        <div class="tree-row_select-block">
          <div class="checkbox "
               *ngIf="isCheckboxShow(node)"
          >

              <!-- ng-class="{ 'indeterminate':ctrl.getIndeterminateState(node) && !node.selected, 'checked warning':ctrl.getIndeterminateState(node) && node.selected }" ng-change="ctrl.select(node, node.selected, $parent.$parent.$parent.node.selected )" ng-disabled="ctrl.isDisabled(node, ctrl)" -->
              <input type="checkbox"
                     class="form-check-input"
                     [ngClass]="{ 'indeterminate ': isIndeterminate(node), 'checked warning':isSubIndeterminate(node) }"
                     [checked]="isChecked(node)"
                     (change)="changeSelected(node, $event)"
                     [name]="getUniqNodeName(node)"
              />


          </div>

          <div class="checkbox "
               *ngIf="isRadioShow(node)"
          >
            <label class="i-checks">
              <input type="checkbox" class="i-radio"
                     [checked]="isChecked(node)"
                     (change)="changeSelected(node, $event)"
                     [name]="getUniqNodeName(node)"
              />
              <i></i>
            </label>
          </div>

        </div>

        <div class="tree-row_expander-icon ">

          <div class=""
               (click)="isNodeFolder(node) && expandNode(node)"
          >
            <i [ngClass]="getIcon(node)"></i>
          </div>

        </div>

        <div class="tree-row_content-name "

        >

            <div class="d-inline-block align-top"
                 (click)="isNodeSku(node) && isCheckboxShow(node) && changeSelected(node)"
            >
              <span [class]="node.data.className + ' good-node-content-wrapper'"
                    [class.title]="true"
                    [class.good-node-content-wrapper-focused]="isEqualNodes(currentNode , node )"
                    (click)="selectNode(node, true)"
                    *ngIf="!isEditNode(node)"
              >{{ node.data.name }}</span>

              <div
                class="d-inline-block position-relative"
                *ngIf="isEditNode(node)">
                <input
                  [(ngModel)]="node.data.$newName"
                  #editNodeInput=""
                  class="form input form-input"
                  (click)="$event.stopPropagation()"
                  (keydown)="changeNodeKeyDown($event, node)"
                  (blur)="updateNodeName(node)" />
                {{ editNodeInput.focus() }}
              </div>


              <span class="text-muted"
                     *ngIf="isNodeSku(node) "
                >
                   {{node.data.id}}
              </span>

            </div>

            <div class="d-inline-block align-top ms-2"
                 *ngIf="isEditMode && isNodeSku(node)"
            >
              <a
                class="text-xs"
                (click)="editClick(node); $event.stopPropagation()"
              >
              <!--  <i class="fas fa-pencil-alt text-primary "></i>
                Редактировать-->

                <i class="fas fa-eye text-success "></i>
                Настройки
              </a>
            </div>

            <div class="d-inline-block align-top ms-2"
                 *ngIf="isNodeFolder(node)"
            >

              <span
                *ngIf="!!node.data?.category?.categoryCount || ( node.data?.category?.categoryCount==0 && node.data?.category?.goodCount==0  )"
              >
                <span class="text-muted">
                  {{node.data?.category?.categoryCount}}
                  <sup><span class="fas fa-folder me-1 text-success"></span></sup>
                </span>
              </span>

              <span
                *ngIf="node.data?.category?.goodCount != undefined"
              >
                <span class="text-muted"> {{ node.data?.category?.goodCount }}
                  <sup><span class="fas fa-tag text-info"></span></sup>
                </span>
              </span>

            </div>

        </div>

        <div class="tree-row_break"></div>
<!--

        <div class="tree-row_sku-row text-muted"
             *ngIf="isNodeSku(node) && isEditMode"
        >
          {{node.data.id}}
        </div>

-->
        <div *ngIf="isLastNode(node)"
             class="tree-row_add-button mt-1"
        >

          <button
            *ngIf="!node?.parent?.data?.$isLoading"
            class="btn btn-default btn-addon btn-sm"
            (click)="getNext.emit(node.parent)"
          >
            <i class="fas fa-plus text-primary"></i>
            <span class="btn-name">
              ещё
            </span>
          </button>

          <div
            *ngIf="!!node?.parent?.data?.$isLoading"
            class="tree-loading-block">
            <i class="fas fa-spin fa-spinner text-warning fa-2x"></i>
          </div>
        </div>

        <div class="tree-row_children"
             *ngIf="isNodeFolder(node)"
        >
          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
        </div>

      </div>

    </ng-template>


    <ng-template #loadingTemplate>
        <div class="tree-loading-block">
          <i class="fas fa-spin fa-spinner text-warning fa-3x"></i>
        </div>
    </ng-template>



  </tree-root>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="w-100 text-center">
      <i class="fas fa-spin fa-spinner text-warning fa-3x"></i>
    </div>
  </ng-container>
</div>

