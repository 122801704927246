import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreComponentsModule } from '../core/components/core.components.module';
import { UIRouterModule } from '@uirouter/angular';
import { AppComponent } from '../app.component';
import { LoginPageModule } from './login/login-page/login-page.module';
import { StoragePageModule } from './storage/storage-page.module';
import { ReportsPageModule } from './reports/reports-page.module';
import { JournalPageModule } from './journal/journal-page.module';
import { SettingsPageModule } from './settings/settings-page.module';
import { APP_ROUTES } from '../../app-routes.variables';
import { LabelPageModule } from './label/label-page.module';
import {RegistrationPageModule} from "./registration/registration-page/registration-page.module";
import {PriceTagsModule} from "./price-tags/price-tags.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreComponentsModule,
    LoginPageModule,
    RegistrationPageModule,
    StoragePageModule,
    UIRouterModule.forChild({
      states: [
        {
          name: APP_ROUTES['root.dashboard.**'].name,
          url: APP_ROUTES['root.dashboard.**'].url,
          loadChildren: () =>
            import('./dashboard/dashboard-page/dashboard-page.module').then(
              mod => mod.DashboardPageModule
            ),
        },
        {
          name: APP_ROUTES['root.player.**'].name,
          url: APP_ROUTES['root.player.**'].url,
          loadChildren: () =>
            import('./player/player-page.module').then(
              mod => mod.PlayerPageModule
            ),
        },
        {
          name: APP_ROUTES['root.users.**'].name,
          url: APP_ROUTES['root.users.**'].url,
          loadChildren: () =>
            import('./directory/users-page/users-page.module').then(
              mod => mod.UsersPageModule
            ),
        },
        {
          name: APP_ROUTES['root.directory.**'].name,
          url: APP_ROUTES['root.directory.**'].url,
          loadChildren: () =>
            import('./directory/directory-page.module').then(
              mod => mod.DirectoryPageModule
            ),
        },
        {
          name: APP_ROUTES['root.playlist.**'].name,
          url: APP_ROUTES['root.playlist.**'].url,
          loadChildren: () =>
            import('./playlist/playlist-page.module').then(
              mod => mod.PlaylistPageModule
            ),
        },
        {
          name: APP_ROUTES['partner.**'].name,
          url: APP_ROUTES['partner.**'].url,
          loadChildren: () =>
            import('./login/partner-page/partner-page.module').then(
              mod => mod.PartnerPageModule
            ),
        },
        {
          name: APP_ROUTES['link.**'].name,
          url: APP_ROUTES['link.**'].url,
          loadChildren: () =>
            import('./login/link-page/link-page.module').then(
              mod => mod.LinkPageModule
            ),
        },
        {
          name: APP_ROUTES['root.schedule.**'].name,
          url: APP_ROUTES['root.schedule.**'].url,
          loadChildren: () =>
            import('./schedule/schedule-page.module').then(
              mod => mod.SchedulePageModule
            ),
        },
        {
          name: APP_ROUTES['root.information.**'].name,
          url: APP_ROUTES['root.information.**'].url,
          loadChildren: () =>
            import(
              './information/information-page/information-page.module'
            ).then(mod => mod.InformationPageModule),
        },
        {
          name: APP_ROUTES['root.locationsGroup.**'].name,
          url: APP_ROUTES['root.locationsGroup.**'].url,
          loadChildren: () =>
            import(
              './locations/locations-group-page/locations-group-page.module'
            ).then(mod => mod.LocationsGroupPageModule),
        },
        {
          name: APP_ROUTES['root.locations.**'].name,
          url: APP_ROUTES['root.locations.**'].url,
          loadChildren: () =>
            import('./locations/locations-page/locations-page.module').then(
              mod => mod.LocationsPageModule
            ),
        },
        {
          name: APP_ROUTES['root.playlist.list.**'].name,
          url: APP_ROUTES['root.playlist.list.**'].url,
          loadChildren: () =>
            import('./playlist/list-page/list-page.module').then(
              mod => mod.ListPageModule
            ),
        },
        {
          name: APP_ROUTES['root.platform.**'].name,
          url: APP_ROUTES['root.platform.**'].url,
          loadChildren: () =>
            import('./platform/platform-page.module').then(
              mod => mod.PlatformPageModule
            ),
        },
        {
          name: APP_ROUTES['root.label.**'].name,
          url: APP_ROUTES['root.label.**'].url,
          loadChildren: () =>
            import('./label/label-page.module').then(
              mod => mod.LabelPageModule
            ),
        },
        {
          name: APP_ROUTES['root.reports.**'].name,
          url: APP_ROUTES['root.reports.**'].url,
          loadChildren: () =>
            import('./reports/reports-page.module').then(
              mod => mod.ReportsPageModule
            ),
        },
        {
          name: APP_ROUTES['root.journal.**'].name,
          url: APP_ROUTES['root.journal.**'].url,
          loadChildren: () =>
            import('./journal/journal-page.module').then(
              mod => mod.JournalPageModule
            ),
        },
        {
          name: APP_ROUTES['root.settings.**'].name,
          url: APP_ROUTES['root.settings.**'].url,
          loadChildren: () =>
            import('./settings/settings-page.module').then(
              mod => mod.SettingsPageModule
            ),
        },
        {
          name: APP_ROUTES['root.device.**'].name,
          url: APP_ROUTES['root.device.**'].url,
          loadChildren: () =>
            import('./device/device-page.module').then(
              mod => mod.DevicePageModule
            ),
        },
        {
          name: APP_ROUTES['root.device-type.**'].name,
          url: APP_ROUTES['root.device-type.**'].url,
          loadChildren: () =>
            import('./device-type/device-type-page.module').then(
              mod => mod.DeviceTypePageModule
            ),
        },
        {
          name: APP_ROUTES['root.price-tags.**'].name,
          url: APP_ROUTES['root.price-tags.**'].url,
          loadChildren: () =>
            import('./price-tags/price-tags.module').then(
              mod => mod.PriceTagsModule
            ),
        },
  /*      {
          name: APP_ROUTES['root.goods.**'].name,
          url: APP_ROUTES['root.goods.**'].url,
          loadChildren: () =>
            import('./goods/goods-page.module').then(
              mod => mod.GoodsPageModule
            ),
        },*/
      ],
    }),
  ],
})
export class PagesModule {}
