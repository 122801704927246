
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {GoodsTreeComponent} from "./components/goods-tree/goods-tree.component";
import {TreeViewComponent} from "./components/tree-view/tree-view.component";
import {TreeModule} from "@circlon/angular-tree-component";
import {GoodsTreeViewComponent} from "./components/goods-tree-view/goods-tree-view.component";
// import {SearchInputModule} from "../search-input/search-input.module";

import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {SelectedViewComponent} from "./components/selected-view/selected-view.component";
import {GoodTreeGetterService} from "./service/good-tree-getter.service";
// import {SharedComponentsModule} from "../shared.components.module";
import {GoodsSelectorService} from "./service/goods-selector.service";
import {ManualGoodInputComponent} from "./components/manual-good-input/manual-good-input.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {CoreComponentsModule} from "../../core/components/core.components.module";
import {NgModule} from "@angular/core";
import {SkuAddPopupComponent} from "./components/sku-add-popup/sku-add-popup.component";
import {CorePipesModule} from "../../core/pipe/core-pipes.module";
import {DsLinkModule} from "../../core/directive/ds-link/ds-link.module";
import {ResponsiveTableModule} from "../../core/components/responsive-table/responsive-table.module";


@NgModule({
  declarations: [
    GoodsTreeComponent,
    TreeViewComponent,
    GoodsTreeViewComponent,
    SelectedViewComponent,
    ManualGoodInputComponent,
    SkuAddPopupComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    TreeModule,
    //       SearchInputModule,
    InfiniteScrollModule,
    //      SharedComponentsModule,
    NgbDropdownModule,
    CoreComponentsModule,
    CorePipesModule,
    DsLinkModule,
    ResponsiveTableModule,
  ],
  providers: [
    GoodTreeGetterService,
    GoodsSelectorService,
  ],
  exports:[
    GoodsTreeComponent
  ]
})
export class GoodsTreeModule {

  constructor() {}

}
