<ng-container *ngIf="hasFields">
  <ngb-accordion #acc="ngbAccordion" [activeIds]="activeGroup()">
    <ng-container *ngFor="let group of fieldByGroups">
      <ngb-panel *ngIf="hasItems(group?.items)" [id]="group.id">
        <ng-template ngbPanelTitle="">
          <span [innerText]="group.name"></span>
        </ng-template>

        <ng-template ngbPanelContent="">
          <div
            class="mb-3"
            *ngFor="let field of group.items | callback: filterData">
            <small
              *ngIf="
                !!field.name && getPrevName(group.items, field) !== field.name
              "
              class="form-label d-block fw-bold mb-2"
              [innerText]="field.name"></small>

            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="fieldTypes.string">
                <textarea
                  rows="2"
                  [name]="'template_field' + field.id + field.path"
                  class="form-control"
                  maxlength="250"
                  [(ngModel)]="field.value">
                </textarea>
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.color">
                <input
                  type="color"
                  [name]="'template_field' + field.id + field.path"
                  class="form-control"
                  [(ngModel)]="field.value" />
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.font_size">
                <select
                  class="form-select"
                  [name]="'template_field' + field.id + field.path"
                  [(ngModel)]="field.value">
                  <option
                    *ngFor="let item of FONT_SIZES | keyvalue"
                    [value]="item.key"
                    [innerText]="item.value"></option>
                </select>
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.file">
                <div class="clearfix"></div>

                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-default"
                    [class.text-success]="field.value && !field.isDefault"
                    (click)="uploadFile(field)"
                    ngbPopover="Загрузить изображение"
                    [triggers]="'mouseenter:mouseleave'"
                    i18n-ngbPopover="@@template-fields_page.upload-image">
                    <i class="fas fas-image fa-lg"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-danger"
                    *ngIf="field.value || field.isDefault"
                    (click)="field.value = field.isDefault ? '' : undefined">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.blockFont">
                <block-font [(value)]="field.value" [options]="field?.options">
                </block-font>
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.blockBackground">
                <block-background
                  [(value)]="field.value"
                  [options]="field?.options">
                </block-background>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>

  <div class="mb-3"></div>
</ng-container>
