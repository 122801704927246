<div class="modal-header  bg-light ng-scope">
  <h5
    class="modal-title">
    Добавление нового товара
  </h5>
</div>
<div class="modal-body p-0 row ms-0 me-0 overflow-auto">

  <div class="col-sm-12">
    <div class="row mb-2">
      <label
        for="good_name"
        class="col-sm-12 col-form-label small "
        i18n="@@sku_add_popup.form-label.good_name"
      >Наименование товара</label
      >

      <div class="col-sm-12">
        <input
          type="text"
          id="good_name"
          name="good_name"
          [required]="true"
          [maxlength]="254"
          class="form-control"
          [(ngModel)]="model.name" />
      </div>
    </div>

    <div class="row mb-2">
      <label
        for="good_sku"
        class="col-sm-12 col-form-label small "
        i18n="@@sku_add_popup.form-label.name"
      >SKU товара</label
      >

      <div class="col-sm-12">
        <input
          type="text"
          id="good_sku"
          name="good_sku"
          [required]="true"
          [maxlength]="254"
          class="form-control"
          [(ngModel)]="model.sku" />
      </div>
    </div>

    <div class="row mb-2">
      <label
        for="good_sku"
        class="col-sm-12 col-form-label small "
        i18n="@@sku_add_popup.form-label.barcode"
      >ШК производителя</label
      >

      <div class="col-sm-12">
        <input
          type="text"
          id="good_barcode"
          name="good_barcode"
          [required]="true"
          [maxlength]="254"
          class="form-control"
          [(ngModel)]="model.barcode" />
      </div>
    </div>

    <div class="row mb-2">
      <label
        for="good_sku"
        class="col-sm-12 col-form-label small "
        i18n="@@sku_add_popup.form-label.description"
      >Описание товара</label
      >

      <div class="col-sm-12">
        <textarea
          type="text"
          id="good_description"
          name="good_description"
          [required]="false"
          [maxlength]="254"
          [rows]="2"
          class="form-control"
          [(ngModel)]="model.description"></textarea>
      </div>
    </div>


  </div>


</div>

<div class="modal-footer b-t bg-light dk ">
  <button
    class="btn btn-success btn-addon btn-sm "
    type="button"
    (click)="saveGood()"
    [disabled]="!model.name || !model.sku"
  >
    <i class="fas fa-check"></i
    ><ng-container
      i18n="@@sku_add_popup.button.select-selected.size">
      Сохранить
    </ng-container>
  </button>

  <button
    class="btn btn-danger btn-sm "
    type="button"
    (click)="activeModal.dismiss()"
  >
    <i class="fas fa-times"></i>
  </button>

</div>
