import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../goods-tree.variables";

import {CategoryApiService} from "../../../core/service/api/category-api/category-api.service";
import {map} from "rxjs/operators";
import {GoodApiService} from "../../../core/service/api/good-api/good-api.service";
import {SearchTreeApiRequest, TreeApiRequest} from "../../../core/service/api/category-api/category-api.values";

@Injectable({
  providedIn: "root",
})
export class GoodTreeGetterService {

  static cache: any;

  constructor(
    @Inject(GoodApiService)     private goodApiService: GoodApiService,
    @Inject(CategoryApiService) private categoryApiService: CategoryApiService,
  ) {
      this.resetCache();
  }

  public resetCache() {
    if ( typeof GoodTreeGetterService.cache === "object")
      Object.keys( GoodTreeGetterService.cache )
        .forEach( i => {
          delete GoodTreeGetterService.cache[i];
        });

    GoodTreeGetterService.cache = {};
  }

  private getMapForPartner(partnerId, type:VIEW_TYPES): Map<any, any> {
    GoodTreeGetterService.cache = GoodTreeGetterService.cache || {};
    GoodTreeGetterService.cache[type] = GoodTreeGetterService.cache[type] || {};
    GoodTreeGetterService.cache[type][partnerId] = GoodTreeGetterService.cache[type][partnerId] || new Map();
    return GoodTreeGetterService.cache[type][partnerId];
  }

  getGood(id, partnerId, returnPromise?) {

    if (typeof id === "object") {
      return  {
        id: id.sku || id.id,
        label: id.name || id.label,
        partnerId: id.partnerId,
        dimension: id.dimension,
      };
    }

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.SKU);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.goodApiService.get$( id, partnerId )
        .subscribe({
          next: ( result ) => {

            if (!result)
              return;

            let res = {
              id: result.sku,
              label: result.name,
              partnerId: result.partnerId,
              dimension: (<any>result)?.dimension || 'weight',
            }

            mapStorage && mapStorage.set(id, res);
            resolve(res);
          },
          error: () => {
            let res = {
              id: id,
              name: 'Товар с ID:' + id
            }
            mapStorage && mapStorage.set(id, res);
            resolve( res );
          }
        });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getCategory(id, partnerId, returnPromise?) {

    if (typeof id === "object")
      return  {
        id: id.categoryId || id.id,
        label: id.name || id.label,
        partnerId: id.partnerId,
        dimension: undefined,
      };

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.CATEGORY);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.categoryApiService.get$( id, partnerId )
      .subscribe({
        next: ( result ) => {
          let res = {
            id: result.categoryId,
            label: result.name,
            partnerId: result.partnerId,
            dimension: undefined,

          }
          mapStorage && mapStorage.set(id, res);
          resolve(res);
        },
        error: () => {
          let res = {
            id: id,
            label: 'Категория с ID:' + id,
            partnerId: partnerId,
          };
          mapStorage && mapStorage.set(id, res);
          resolve( res );
        }
      });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getTree(partnerId, filterRootId?,  drop?, limit?, sort?, filterDimensions?) {

    return new Promise( (resolve, reject) => {

      filterDimensions = filterDimensions && !Array.isArray(filterDimensions) ? [ filterDimensions ] : filterDimensions;

      let requestParams: TreeApiRequest = {
        partnerId: partnerId,
        "pager.drop": drop,
        "pager.limit": limit,
        "filter.rootId": filterRootId,
//        filterDimensions
      };

      if (!!sort) {
        requestParams.sort = sort;
      }

      this.categoryApiService.tree$(requestParams)
        .subscribe({
          next: result => {
            resolve( result );
          },
          error: reject
        })
    })

  }

  getSearchTree(partnerId, filterSentence, filterRootId?, sort?, filterDimensions? ) {

    return new Promise( (resolve, reject) => {

      filterDimensions = filterDimensions && !Array.isArray(filterDimensions) ? [ filterDimensions ] : filterDimensions;

      let requestParams: SearchTreeApiRequest = {
        partnerId: partnerId,
        "filter.rootId": filterRootId,
        "filter.dimensions": filterDimensions,
        "filter.sentence": filterSentence
      };

      if (!!sort) {
        requestParams.sort = sort;
      }

      this.categoryApiService.tree$(requestParams)
        .subscribe({
          next: result => {
            resolve( result );
          },
          error: reject
        })
    })

  }

  getIdByType(id, partnerId, viewType: VIEW_TYPES) {

    switch (viewType) {

      case VIEW_TYPES.SKU:
        return this.goodApiService.get$( id, partnerId )
          .toPromise()
          .then( result =>{

            if (!result)
              return undefined;

            this.getMapForPartner(partnerId, VIEW_TYPES.SKU).set(id, {
              id: result.sku,
              label: result.name,
              partnerId: result.partnerId,
              dimension: (<any>result).dimension || 'piece',
            });

            return result.sku
          }).catch(error => {
            error?.stopPopupError();
            return Promise.reject(error);
          });

      case VIEW_TYPES.CATEGORY:
        return this.categoryApiService.get$( id, partnerId )
          .toPromise()
          .then( result =>{

            if (!result)
              return undefined;

            this.getMapForPartner(partnerId, VIEW_TYPES.CATEGORY).set(id,  {
              id: result.categoryId,
              label: result.name,
              partnerId: result.partnerId,
              dimension: undefined,

            });
            return result.categoryId
          }).catch(error => {
            error?.stopPopupError();
            return Promise.reject(error);
          });;

    }

    return Promise.resolve(undefined);
  }

  async getResultById(ids=[], partnerId, currentView: VIEW_TYPES) {

    let mapStorage = this.getMapForPartner(partnerId, currentView);

    let promises = ids.slice()
      .map( async item => {

        switch (currentView) {

          case VIEW_TYPES.SKU:
            return this.getGood(item, partnerId, true);

          case VIEW_TYPES.CATEGORY:
            return this.getCategory(item, partnerId, true);

            }
      })

    return await Promise.all(promises);
  }

}
