
import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, Optional} from "@angular/core";

import {VIEW_TYPES, VIEW_TYPES_NAMES} from "./../../goods-tree.variables";
import {GoodTreeGetterService} from "./../../service/good-tree-getter.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PartnerApiService} from "../../../../core/service/api/partner-api/partner-api.service";

const LIMITS_ALL_ID= '$ALL$';

@Component({
  selector: 'goods-tree',
  templateUrl: "./goods-tree.component.html",
  styleUrls: ['./goods-tree.component.scss'],
  providers: [
    PartnerApiService,
  ]
})
 export class GoodsTreeComponent implements OnInit, OnChanges{

  //-------------LEGACY--------------

  showPartner = true

  //-------------LEGACY--------------

  @Input()  selectedSku = new Set<number>();
  @Input()  selectedCategory = new Set<number>();
  @Input()  selectedBrand = new Set<number>();
  @Input()  selectedGoodGroup = new Set<number>();

  //----------

  @Input()  editCallBack: any;

  @Input() public partnerId;
  public isPartnerSetted = false;
  public partnerList:any[] = [];

  public currentView;
  public VIEW_TYPES = VIEW_TYPES;
  public viewList: any[];

  public limits = {}
  public dimension;
  public isSkuHide;
  public isShowArchiveSku;
  public selectOne;

  public sortingTypes = {
    'none' : 'Без сортировки',
    'name' : 'По имени'
  }
  public sortingField;

  searchString = ''

  constructor(
      private partnerService : PartnerApiService,
      public goodTreeGetterService: GoodTreeGetterService,
      @Optional() private activeModal: NgbActiveModal
  )
  {

  }

  ngOnInit() {


    if (!this.viewList)
      this.setViewList( Object.values(VIEW_TYPES)/*.filter(i => i !== VIEW_TYPES.GOODGROUP_SKU)*/ );

    this.getPartnerList();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['editCallBack'] && this.isEditMode) {
      this.currentView = VIEW_TYPES.SKU;
    }

  }

  // Список представлений
  public setViewList( values: VIEW_TYPES[] = [], defaultSelect?:VIEW_TYPES ) {

    this.viewList = [];

    values.forEach( key => {
      this.viewList.push ( {
        id: key,
        label: VIEW_TYPES_NAMES[key]
      } )
    })

    if ( !this.currentView || !this.viewList.some( i => i.id === this.currentView) ) {
      this.currentView = this.viewList.length ? this.viewList[0].id : undefined;
    }

  }

  // Партнёр по умолчанию
  public setPartner( id: number, canEdit = true ) {
    this.isPartnerSetted = !canEdit;
    this.partnerId = id;
  }

  // Установка лимитов
  public setMaximumLimit( value: number | any) {

    if (typeof value === "number") {
      this.limits[LIMITS_ALL_ID] = this.limits[LIMITS_ALL_ID]  || {};
      this.limits[LIMITS_ALL_ID].max = value;
      return
    }

    if (typeof value === "object") {
      Object.keys(value)
            .forEach( key => {

              if ( !Object.values(VIEW_TYPES).includes( <any>key.toLowerCase() ) )
                return;

              this.limits[key.toLowerCase()] = this.limits[key.toLowerCase()]  || {};
              this.limits[key.toLowerCase()].max = value[key];

            })
    }

  }

  // Установка дополнительных настроек запроса
  public setParamsForQuery( params = {} ) {

    if ( typeof params['filter.dimensions'] !== "undefined" ) {
      this.dimension = params['filter.dimensions'];
      this.dimension = !Array.isArray(this.dimension) ? [ this.dimension ] : this.dimension;
    }

  }

  // Установка скрытия SKU
  public setHideSku( value = true ) {
    this.isSkuHide = value
  }

  // Установка отображения архивных
  public setShowArchiveSku( value = true ) {
    this.isShowArchiveSku = value
  }

  // Установка только одного значения
  public setOneSelect( value = true ) {
    this.selectOne = value;
  }

  public changeView(newValue) {
    this.currentView = newValue;
  }

  get hasSelected() {
    let selectedMap = this.getSelectedMap;
    return selectedMap ? !!this.getSelectedMap?.size : false
  }

  get getSelectedMap() : any {
    switch (this.currentView) {

      case VIEW_TYPES.SKU:
        return this.selectedSku;

      case VIEW_TYPES.CATEGORY:
        return this.selectedCategory ;
    }

    return false;
  }

  get maximumLimit() : number | undefined {

    if ( typeof this.limits[this.currentView]?.max !== "undefined" ) {
      return this.limits[this.currentView]?.max;
    }

    if ( typeof this.limits[LIMITS_ALL_ID]?.max !== "undefined" ) {
      return this.limits[LIMITS_ALL_ID]?.max;
    }

    return undefined
  }

  get isEditMode() {
    return typeof this.editCallBack === "function"
  }

  getPartnerList() {
    this.partnerService
        .query$()
        .subscribe({
          next: result => {
            this.partnerList = result.map( i => ({
                id: i.id,
                value: i.name,
              })
            );

            if ( typeof this.partnerId === "undefined")
              this.partnerId = this.partnerList[0]?.id;

          }

        })
  }

  cancel() {
    this.goodTreeGetterService.resetCache();
    this.activeModal.dismiss(false);
  }

  public async prepareResult() {

    let result = {
      list: [] as any[],
      partnerId: this.partnerId,
      type: this.currentView
    }

    if (!this.hasSelected)
      return result;

    const selectedMap = this.getSelectedMap;
    if (!selectedMap)
      return result;

    result.list = await this.goodTreeGetterService.getResultById( Array.from( selectedMap?.values()), this.partnerId, this.currentView );

    return result;

  }

  async select() {
    let result = await this.prepareResult();
    this.activeModal.close( result );
    this.goodTreeGetterService.resetCache();
  }


  public forCallbacks = (value) => {
    if (!this.isEditMode)
      return

    this.editCallBack(value);
  }

  public isLimitError() {
    return ( !!this.maximumLimit && this.maximumLimit > 0 ) && this.getSelectedMap?.size > this.maximumLimit
  }

}
