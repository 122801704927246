/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CategoryApi } from '../models/category-api';
import { RawCategorySearchResult } from '../models/raw-category-search-result';
import { RawCategoryTree } from '../models/raw-category-tree';

@Injectable({
  providedIn: 'root',
})
export class CategoryApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPartnerPartneridCategory
   */
  static readonly GetPartnerPartneridCategoryPath = '/partner/{partnerId}/category';

  /**
   * Запрос категорий по указанному партнёру
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategory$Response(params: {
    partnerId: number;
  }): Observable<StrictHttpResponse<Array<CategoryApi>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.GetPartnerPartneridCategoryPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CategoryApi>>;
      })
    );
  }

  /**
   * Запрос категорий по указанному партнёру
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategory(params: {
    partnerId: number;
  }): Observable<Array<CategoryApi>> {

    return this.getPartnerPartneridCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CategoryApi>>) => r.body as Array<CategoryApi>)
    );
  }

  /**
   * Path part for operation getPartnerPartneridCategorySearch
   */
  static readonly GetPartnerPartneridCategorySearchPath = '/partner/{partnerId}/category/search';

  /**
   * Поиск товара в дереве категорий
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridCategorySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategorySearch$Response(params: {
    partnerId: number;

    /**
     * ID корневой категории, от которой построится дерево
     */
    'filter.rootId'?: number;
    'filter.sentence'?: string;
    'filter.dimensions'?: number;

    /**
     * Сортировка, список полей из модели, по которым будет произведена сортировка.
     * По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию,
     * то указать знак &quot;-&quot; перед именем поля модели.
     * Пример: sort&#x3D;name,-categoryId.
     */
    sort?: string;
  }): Observable<StrictHttpResponse<RawCategorySearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.GetPartnerPartneridCategorySearchPath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.query('filter.rootId', params['filter.rootId'], {});
      rb.query('filter.sentence', params['filter.sentence'], {});
      rb.query('filter.dimensions', params['filter.dimensions'], {});
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RawCategorySearchResult>;
      })
    );
  }

  /**
   * Поиск товара в дереве категорий
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridCategorySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategorySearch(params: {
    partnerId: number;

    /**
     * ID корневой категории, от которой построится дерево
     */
    'filter.rootId'?: number;
    'filter.sentence'?: string;
    'filter.dimensions'?: number;

    /**
     * Сортировка, список полей из модели, по которым будет произведена сортировка.
     * По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию,
     * то указать знак &quot;-&quot; перед именем поля модели.
     * Пример: sort&#x3D;name,-categoryId.
     */
    sort?: string;
  }): Observable<RawCategorySearchResult> {

    return this.getPartnerPartneridCategorySearch$Response(params).pipe(
      map((r: StrictHttpResponse<RawCategorySearchResult>) => r.body as RawCategorySearchResult)
    );
  }

  /**
   * Path part for operation getPartnerPartneridCategoryTree
   */
  static readonly GetPartnerPartneridCategoryTreePath = '/partner/{partnerId}/category/tree';

  /**
   * Получение дерева категорий/товаров
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartnerPartneridCategoryTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategoryTree$Response(params: {
    partnerId: number;

    /**
     * ID корневой категории, от которой построится дерево
     */
    'filter.rootId'?: number;

    /**
     * Сортировка, список полей из модели, по которым будет произведена сортировка.
     * По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию,
     * то указать знак &quot;-&quot; перед именем поля модели.
     * Пример: sort&#x3D;name,-categoryId.
     */
    sort?: string;

    /**
     * Число пропускаемых элементов (по умлочнию 0)
     */
    'pager.drop'?: number;

    /**
     * Число элементов для выборки
     */
    'pager.limit'?: number;
  }): Observable<StrictHttpResponse<RawCategoryTree>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.GetPartnerPartneridCategoryTreePath, 'get');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.query('filter.rootId', params['filter.rootId'], {});
      rb.query('sort', params.sort, {});
      rb.query('pager.drop', params['pager.drop'], {});
      rb.query('pager.limit', params['pager.limit'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RawCategoryTree>;
      })
    );
  }

  /**
   * Получение дерева категорий/товаров
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartnerPartneridCategoryTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartnerPartneridCategoryTree(params: {
    partnerId: number;

    /**
     * ID корневой категории, от которой построится дерево
     */
    'filter.rootId'?: number;

    /**
     * Сортировка, список полей из модели, по которым будет произведена сортировка.
     * По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию,
     * то указать знак &quot;-&quot; перед именем поля модели.
     * Пример: sort&#x3D;name,-categoryId.
     */
    sort?: string;

    /**
     * Число пропускаемых элементов (по умлочнию 0)
     */
    'pager.drop'?: number;

    /**
     * Число элементов для выборки
     */
    'pager.limit'?: number;
  }): Observable<RawCategoryTree> {

    return this.getPartnerPartneridCategoryTree$Response(params).pipe(
      map((r: StrictHttpResponse<RawCategoryTree>) => r.body as RawCategoryTree)
    );
  }

  /**
   * Path part for operation putPartnerPartneridCategoryCreate
   */
  static readonly PutPartnerPartneridCategoryCreatePath = '/partner/{partnerId}/category/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPartnerPartneridCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridCategoryCreate$Response(params: {
    partnerId: number;

    /**
     * Создание категории товаров
     */
    body: CategoryApi
  }): Observable<StrictHttpResponse<CategoryApi>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.PutPartnerPartneridCategoryCreatePath, 'put');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPartnerPartneridCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPartnerPartneridCategoryCreate(params: {
    partnerId: number;

    /**
     * Создание категории товаров
     */
    body: CategoryApi
  }): Observable<CategoryApi> {

    return this.putPartnerPartneridCategoryCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryApi>) => r.body as CategoryApi)
    );
  }

  /**
   * Path part for operation postPartnerPartneridCategoryCategoryid
   */
  static readonly PostPartnerPartneridCategoryCategoryidPath = '/partner/{partnerId}/category/{categoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPartnerPartneridCategoryCategoryid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCategoryCategoryid$Response(params: {
    partnerId: number;
    categoryId: number;

    /**
     * Изменить указанную категорию
     */
    body: CategoryApi
  }): Observable<StrictHttpResponse<CategoryApi>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.PostPartnerPartneridCategoryCategoryidPath, 'post');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('categoryId', params.categoryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPartnerPartneridCategoryCategoryid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPartnerPartneridCategoryCategoryid(params: {
    partnerId: number;
    categoryId: number;

    /**
     * Изменить указанную категорию
     */
    body: CategoryApi
  }): Observable<CategoryApi> {

    return this.postPartnerPartneridCategoryCategoryid$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryApi>) => r.body as CategoryApi)
    );
  }

  /**
   * Path part for operation deletePartnerPartneridCategoryCategoryid
   */
  static readonly DeletePartnerPartneridCategoryCategoryidPath = '/partner/{partnerId}/category/{categoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerPartneridCategoryCategoryid()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridCategoryCategoryid$Response(params: {
    partnerId: number;
    categoryId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CategoryApiService.DeletePartnerPartneridCategoryCategoryidPath, 'delete');
    if (params) {
      rb.path('partnerId', params.partnerId, {});
      rb.path('categoryId', params.categoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePartnerPartneridCategoryCategoryid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerPartneridCategoryCategoryid(params: {
    partnerId: number;
    categoryId: number;
  }): Observable<void> {

    return this.deletePartnerPartneridCategoryCategoryid$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
