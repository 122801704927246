import { NgModule } from '@angular/core';

import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AppLogoComponent } from './app-logo/app-logo.component';
import { AppNavComponent } from './app-nav/app-nav.component';
import { AppNavFooterComponent } from './app-nav-footer/app-nav-footer.component';
import { AppUtilitesComponent } from './app-utilites/app-utilites.component';
import { AppBreadcrumbsComponent } from './app-breadcrumbs/app-breadcrumbs.component';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { AppTopPanelComponent } from './app-top-panel/app-top-panel.component';
import { AppPageColumnComponent } from './app-page-column/app-page-column.component';
import { CorePipesModule } from '../pipe/core-pipes.module';
import { FormsModule } from '@angular/forms';
import { AppSearchInputComponent } from './app-search-input/app-search-input.component';
import { AppSortViewComponent } from './app-sort-view/app-sort-view.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastService } from './toasts-container/toast.service';
import { AppLoadingComponent } from './app-loading/app-loading.component';
import { ScheduleStatusComponent } from './schedule-status/schedule-status.component';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { TextHighlightComponent } from './text-hightlight/text-hightlight.component';
import { AppTagInputComponent } from './app-tag-input/app-tag-input.component';
import { TagInputModule } from 'ngx-chips';
import { ReactiveFormsModule } from '@angular/forms';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { CoreDirectiveModule } from '../directive/core-directive.module';
import { SelectLocationComponent } from './select-location/select-location.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDeviceTypeComponent } from './select-device-type/select-device-type.component';
import { SelectPlatformComponent } from './select-platform/select-platform.component';
import { DsLinkModule } from '../directive/ds-link/ds-link.module';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import {SelectAccessPointModelComponent} from "./select-access-point-model/select-access-point-model.component";
import {SelectEslModelComponent} from "./select-esl-model/select-esl-model.component";

@NgModule({
  declarations: [
    AppLayoutComponent,
    AppLogoComponent,
    AppNavComponent,
    AppNavFooterComponent,
    AppUtilitesComponent,
    AppBreadcrumbsComponent,
    AppTopPanelComponent,
    AppPageColumnComponent,
    AppSearchInputComponent,
    AppSortViewComponent,
    ToastsContainerComponent,
    AppLoadingComponent,
    ScheduleStatusComponent,
    CheckboxFilterComponent,
    TextHighlightComponent,
    AppTagInputComponent,
    FilePreviewComponent,
    SelectLocationComponent,
    SelectDeviceTypeComponent,
    SelectPlatformComponent,
    LanguageSelectorComponent,
    SelectAccessPointModelComponent,
    SelectEslModelComponent,
  ],
  imports: [
    NgbModule,
    NgbToastModule,
    UIRouterModule.forChild(),
    CommonModule,
    CorePipesModule,
    FormsModule,
    FontAwesomeModule,
    TagInputModule,
    ReactiveFormsModule,
    CoreDirectiveModule,
    DsLinkModule,
    NgSelectModule,
  ],
  providers: [],
  exports: [
    AppLayoutComponent,
    AppLogoComponent,
    AppNavComponent,
    AppNavFooterComponent,
    AppUtilitesComponent,
    AppBreadcrumbsComponent,
    AppTopPanelComponent,
    AppPageColumnComponent,
    AppSearchInputComponent,
    AppSortViewComponent,
    ToastsContainerComponent,
    AppLoadingComponent,
    ScheduleStatusComponent,
    CheckboxFilterComponent,
    TextHighlightComponent,
    AppTagInputComponent,
    FilePreviewComponent,
    SelectLocationComponent,
    SelectDeviceTypeComponent,
    SelectPlatformComponent,
    LanguageSelectorComponent,
    SelectAccessPointModelComponent,
    SelectEslModelComponent
  ],
})
export class CoreComponentsModule {}
